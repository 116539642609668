import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import styled from '@emotion/styled'
import { rgba } from 'polished'
import Fade from 'react-reveal/Fade'

import Theme, { Mq } from '../themes/default'
import { Row, Column } from '../atoms/grid'
import { H3 } from '../atoms/heading'
import { Paragraph } from '../atoms/text'
import Layout from '../organisms/layout'
import SEO from '../organisms/seo'
import { SubpageHero } from '../molecules/subpage-hero'
import { FooterHero } from '../molecules/footer-hero'

const FaqSectionContainer = styled.div`
  padding: 1rem 5%;

  ${Mq.md} {
    padding: 3rem 3% 7rem;
    margin: 0 5%;
  }
  ${Mq.xxl} {
    margin: 0 15%;
  }
`

const FaqSectionRow = styled(Row)`
  padding: 2rem 0;

  &:not(:last-child) {
    border-bottom: 1px solid ${rgba(Theme.colors.darkBlue, 0.1)};
  }

  ${Mq.md} {
    padding: 3rem 0;
  }
`

const FaqQuestion = styled(H3)`
  max-width: 760px;
  padding-bottom: 1rem;
`

const FaqAnswer = styled(Paragraph)`
  padding-bottom: 1rem;
`

const FAQ = () => {
  const { contentfulFaqPage } = useStaticQuery(
    graphql`
      query FaqQuery {
        contentfulFaqPage {
          pageTitle
          pageDescription
          title
          description
          FAQs {
            question
            answer {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
    `,
  )

  return (
    <Layout>
      <SEO description={contentfulFaqPage.pageDescription} title={contentfulFaqPage.pageTitle} />
      <SubpageHero title={contentfulFaqPage.title} text={contentfulFaqPage.description} />
      <Fade bottom={true} cascade={true} distance={Theme.fadeDistance}>
        <FaqSectionContainer>
          {contentfulFaqPage.FAQs.map(faq => {
            return (
              <FaqSectionRow flexWrap="wrap" key={faq.question}>
                <Column flex={1} order={[2, 2, 1]} pr={[0, 0, 4]}>
                  <FaqQuestion>{faq.question}</FaqQuestion>

                  <FaqAnswer
                    dangerouslySetInnerHTML={{
                      __html: faq.answer.childMarkdownRemark.html,
                    }}
                  />
                </Column>
              </FaqSectionRow>
            )
          })}
        </FaqSectionContainer>
      </Fade>

      <FooterHero />
    </Layout>
  )
}

export default FAQ
